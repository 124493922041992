function resetGroupedActions() {
    $('.progress-bar').removeClass('bg-success').css('width', '0');
    $('#progressbar').hide();
    $('#group-action-choice').show();
}

function testProgress(operation) {

    $('#group-action-choice').hide().val("");

    $('#progressbar').show();
    return setInterval(function () {
        $.getJSON('/ajax/progress/' + operation, function (data) {
            $('.progress-bar').css('width', data + '%');
        });
    }, 1000);
}

function createModal(msgBody,urldownload=false,reload=false) {
    $('#groupedMsg').html(msgBody);
    if(urldownload) $('#btn-download').html('<i class="fa fa-download"></i> Télécharger').attr('href', urldownload);
    else if(reload) $('#btn-download').html('Fermer').attr('href', "javascript:document.location.reload();");
    else $('#btn-download').html('Fermer').attr('href', "#");
    $('#groupedModal').modal({focus:true});
}

function createConfirmBox(msgBody,value) {
    $('#confirmMsg').html(msgBody);
    $('#btn-confirm').data('value', value);
    $('#confirmModal').modal({focus:true});
}

$(document).ready(function () {

    $(document).delegate('#selectAll', 'click', function () {
        if ($(this).is(':checked')) $('.js-group-selection').attr('checked', 'checked').prop('checked', true);
        else $('.js-group-selection').removeAttr('checked').prop('checked', false);
    })
    $(document).delegate('#btn-download', 'click', function (e) {
        e.preventDefault()
        document.location = $(this).attr("href")
        resetGroupedActions()
    })

    $(document).delegate('#group-action-choice', 'change', function () {
        var value = $(this).val();
        var sessions = [];

        //Liste des sessions
        $(".js-group-selection").each(function () {
            if ($(this).is(':checked')) sessions.push($(this).val())
        })

        var msgConfirm="";

        switch (value) {
            case "" :
                return $(this).val('');
            case "close-sessions" :
                msgConfirm = 'Souhaitez-vous clôturer ces ' + sessions.length + ' sessions';
                break;
            case "validate-practices" :
                msgConfirm = 'Souhaitez-vous valider les pratiques de ces ' + sessions.length + ' sessions';
                break;
            case "generate_attestations" :
                msgConfirm = 'Souhaitez-vous télécharger les attestations de ces ' + sessions.length + ' sessions';
                break;
            case "generate_presences" :
                msgConfirm = 'Souhaitez-vous télécharger les feuilles de présence de ces ' + sessions.length + ' sessions';
                break;
            case "generate_bilans" :
                msgConfirm = 'Souhaitez-vous télécharger les bilans de ces ' + sessions.length + ' sessions';
                break;
            case "generate_leaners_list" :
                msgConfirm = 'Souhaitez-vous télécharger les listes d\'apprenants de ces ' + sessions.length + ' sessions';
                break;
            case "generate_stats" :
                msgConfirm = 'Souhaitez-vous télécharger les statistiques de ces ' + sessions.length + ' sessions';
                break;
        }

        if (sessions.length > 0) {
            createConfirmBox(msgConfirm,value)
        } else return false;
    })

    $(document).delegate('#btn-confirm', 'click', function () {
        var value = $(this).data('value');
        var sessions = [];

        //Liste des sessions
        $(".js-group-selection").each(function () {
            if ($(this).is(':checked')) sessions.push($(this).val())
        })
        var url = '';
        var msgConfirm = '';
        var msgSuccess = '';
        var msgFail = '';
        var resource = "";
        var msgBody="";

        const operation = Date.now();
        var progress;
        var reload = false;

        if (sessions.length > 0) {
            switch (value) {
                case "" :
                    return $(this).val('');
                case "close-sessions" :
                    url = '/ajax/session/close-sessions';
                    msgConfirm = 'Souhaitez-vous clôturer ces ' + sessions.length + ' sessions';
                    msgSuccess = 'Les ' + sessions.length + ' sessions sont cloturées, veuillez valider pour recharger les informations';
                    reload = true;
                    break;
                case "validate-practices" :
                    url = '/ajax/session/validate-practices';
                    msgConfirm = 'Souhaitez-vous valider les pratiques de ces ' + sessions.length + ' sessions';
                    msgSuccess = 'Les pratiques de ces ' + sessions.length + ' sessions sont validées';
                    break;
                case "generate_attestations" :
                    url = '/ajax/session/generer/attestations';
                    msgConfirm = 'Souhaitez-vous télécharger les attestations de ces ' + sessions.length + ' sessions';
                    msgSuccess = 'Cliquez ci-dessous pour télécharger les attestations générées';
                    msgFail = 'Aucune attestation ne peut être téléchargée';
                    resource = "attestations";
                    break;
                case "generate_presences" :
                    url = '/ajax/session/generer/presences';
                    msgConfirm = 'Souhaitez-vous télécharger les feuilles de présence de ces ' + sessions.length + ' sessions';
                    msgSuccess = 'Cliquez ci-dessous pour télécharger les feuilles de présence générées';
                    msgFail = 'Aucune feuille de présence ne peut être téléchargée';
                    resource = "presences";
                    break;
                case "generate_bilans" :
                    url = '/ajax/session/generer/bilans';
                    msgConfirm = 'Souhaitez-vous télécharger les bilans de ces ' + sessions.length + ' sessions';
                    msgSuccess = 'Cliquez ci-dessous pour télécharger les bilans générés';
                    msgFail = 'Aucun bilan ne peut être téléchargé';
                    resource = "bilans";
                    break;
                case "generate_leaners_list" :
                    url = '/ajax/session/generer/learnerslist';
                    msgConfirm = 'Souhaitez-vous télécharger les listes d\'apprenants de ces ' + sessions.length + ' sessions';
                    msgSuccess = 'Cliquez ci-dessous pour télécharger les listes générées';
                    msgFail = 'Aucune liste d\'apprenant ne peut être téléchargée';
                    resource = "learnerslist";
                    break;
                case "generate_stats" :
                    url = '/ajax/session/generer/stats';
                    msgConfirm = 'Souhaitez-vous télécharger les statistiques de ces ' + sessions.length + ' sessions';
                    msgSuccess = 'Cliquez ci-dessous pour télécharger les statistiques';
                    msgFail = 'Aucun export de statistique ne peut être téléchargé';
                    resource = "stats";
                    break;
            }

                progress = testProgress(operation);

                $.ajax({
                    url: url,
                    type: 'POST',
                    data: {sessions: sessions, resource: resource, operation: operation},
                    dataType: 'json',
                    success: function (data) {
                       clearInterval(progress);
                       $('.progress-bar').css('width', '100%').addClass('bg-success');
                       $('#group-action-choice').val("");
                        var urldownload=false;
                       if (data.linkDownload) {
                           urldownload = "/ajax/session/telecharger/" + resource + "/" + operation;
                       }
                       if(data.no_items) msgBody=msgFail; else msgBody=msgSuccess;
                       createModal(msgBody,urldownload, reload);
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        clearInterval(progress)
                        console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
                    }
                });



        }
    })
})